.AppLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

select {
    width: 100%;
    height: 40px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

input {
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

.FormContainer {
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    width: 25%;
    box-shadow: 12px 12px 2px 1px rgba(56, 56, 56, 0.2);
}