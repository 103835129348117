.AnnoucementView {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.annoucement-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}

.annoucement-text, .annoucement-edit  {
    font-size: 15px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    background: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
}

.annoucement-edit-textarea {
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
}
